import React, { useState, useEffect } from "react";
import Joi from "joi-browser";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { Alert, Button, Container, FormGroup, Input, Label } from "reactstrap";
import StripeCard from "../../components/StripeCard";

const customerValidationSchema = {
  firstName: Joi.string().required().label("First Name"),
  lastName: Joi.string().required().label("Last Name"),
  email: Joi.string().email().required().label("Email"),
  description: Joi.any().optional().label("Note"),
};

const PatientRegistrationForm = ({
  isLoading,
  isSuccess,
  customer,
  setCustomer,
  handleSubmit,
}) => {
  const stripe = useStripe();
  const elements = useElements();

  const [errors, setErrors] = useState({});
  const [cardError, setCardError] = useState("");

  useEffect(() => {
    if (isSuccess) {
      elements.getElement(CardElement).clear();
    }
  }, [elements, isSuccess]);

  const validate = () => {
    const options = { abortEarly: false };
    const { error } = Joi.validate(customer, customerValidationSchema, options);
    if (!error) {
      return null;
    }
    const errors = {};
    for (let item of error?.details) {
      errors[item.path[0]] = item.message;
    }
    return errors;
  };

  const validateInputField = (name, value) => {
    const obj = { [name]: value };
    const schema = { [name]: customerValidationSchema[name] };
    const { error } = Joi.validate(obj, schema);
    return error ? error?.details[0]?.message : null;
  };

  const handleChange = (key, value) => {
    const _errors = { ...errors };
    let data = { ...customer };
    const errorMessage = validateInputField(key, value);
    if (errorMessage) {
      _errors[key] = errorMessage;
    } else {
      delete _errors[key];
    }

    data[key] = value;
    setErrors(_errors);
    setCustomer(data);
  };

  const handleCardDetails = async (event) => {
    event.preventDefault();
    const invalidObject = validate();
    if (invalidObject) {
      setErrors(invalidObject);
      return;
    }

    if (!stripe || !elements) {
      return;
    }

    const card = elements.getElement(CardElement);
    const result = await stripe.createToken(card);

    if (result.error) {
      setCardError(result.error.message);
    } else {
      setCardError("");
      const payload = {
        customer: {
          name: `${customer.firstName} ${customer.lastName}`,
          email: customer.email,
          description: customer.description,
        },
        card: result.token.id,
      };
      handleSubmit(payload);
    }
  };

  return (
    <Container>
      <FormGroup>
        <Label>First Name</Label>
        <Input
          value={customer.firstName}
          onChange={(e) => handleChange("firstName", e.target.value)}
          placeholder="Enter Customer's First Name"
        />
        {errors?.firstName && (
          <Alert color="danger" className="mt-1">
            {errors?.firstName}
          </Alert>
        )}
      </FormGroup>
      <FormGroup>
        <Label>Last Name</Label>
        <Input
          value={customer.lastName}
          onChange={(e) => handleChange("lastName", e.target.value)}
          placeholder="Enter Customer's Last Name"
        />
        {errors?.lastName && (
          <Alert color="danger" className="mt-1">
            {errors?.lastName}
          </Alert>
        )}
      </FormGroup>
      <FormGroup>
        <Label>Email</Label>
        <Input
          type="email"
          value={customer.email}
          onChange={(e) => handleChange("email", e.target.value)}
          placeholder="Enter Email Address"
        />
        {errors?.email && (
          <Alert color="danger" className="mt-1">
            {errors?.email}
          </Alert>
        )}
      </FormGroup>
      <FormGroup>
        <Label>Notes</Label>
        <Input
          type="textarea"
          rows={3}
          value={customer.description}
          onChange={(e) => handleChange("description", e.target.value)}
          placeholder="Add a note"
        />
        <small className="float-right mt-1 text-muted">0/250</small>
      </FormGroup>

      <FormGroup>
        <Label>Card Details</Label>
        <StripeCard />
        {cardError && (
          <Alert color="danger" className="mt-2">
            {cardError}
          </Alert>
        )}
      </FormGroup>
      <Button
        block
        color="primary"
        onClick={(e) => handleCardDetails(e)}
        disabled={isLoading}
      >
        SAVE
      </Button>
    </Container>
  );
};

export default PatientRegistrationForm;
