import React, { useState, useEffect } from "react";
import { useQuery } from "react-query";
import { Spinner } from "reactstrap";
import Customers from "../../pages/Customers";
import { keys } from "../../query/keys";
import { getAllCustomers } from "../../query/queryFunctions";

const CustomersContainer = () => {
  const [searchText, setSearchText] = useState("");
  const [customers, setCustomers] = useState([]);

  const { isLoading, data: allCustomersList } = useQuery(
    keys.customers,
    getAllCustomers
  );

  useEffect(() => {
    if (allCustomersList?.customers) {
      setCustomers(allCustomersList?.customers);
    }
  }, [allCustomersList]);

  if (isLoading) {
    return (
      <div className="text-center mt-5">
        <Spinner color="primary" />
      </div>
    );
  }

  const getFilteredCustomers = () => {
    let filteredCustomers = [];
    filteredCustomers =
      allCustomersList?.customers &&
      allCustomersList?.customers?.filter(
        (customer) =>
          customer.name.toLowerCase().includes(searchText.toLowerCase()) ||
          customer.email.toLowerCase().includes(searchText.toLowerCase())
      );
    return filteredCustomers?.length > 0 ? filteredCustomers : [];
  };

  return (
    <Customers
      customers={getFilteredCustomers()}
      searchText={searchText}
      setSearchText={setSearchText}
    />
  );
};

export default CustomersContainer;
