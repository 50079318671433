import React from "react";
import { Button } from "reactstrap";

const IconButton = ({ title, icon, ...props }) => {
  return (
    <Button {...props}>
      <div className="d-flex align-items-center">
        {icon}
        <span>{title}</span>
      </div>
    </Button>
  );
};

export default IconButton;
