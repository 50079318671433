import React, { useState, useEffect } from "react";
import Joi from "joi-browser";
import { useMutation, useQueryClient } from "react-query";
import {
  XCircle,
  CloudArrowDownFill,
  PlusCircle,
  PencilFill,
} from "react-bootstrap-icons";
import {
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Col,
  Alert,
} from "reactstrap";
import DatePicker from "react-datepicker";
import { toast } from "react-toastify";
import IconButton from "../Button";
import {
  updateInvoice,
  createInvoiceItem,
  updateInvoiceItem,
} from "../../query/queryFunctions";
import "react-datepicker/dist/react-datepicker.css";
import { keys } from "../../query/keys";

const invoiceItemValidationSchema = {
  description: Joi.string().required().label("Item Name"),
  amount: Joi.number().min(1).positive().required().label("Price"),
};

const InvoiceUpdateModal = ({ isOpen, toggle, invoice }) => {
  const queryClient = useQueryClient();
  const [invoiceData, setInvoiceData] = useState({
    dueDate: new Date().setDate(new Date().getDate() + 1),
    description: "",
  });
  const [itemErrors, setItemErrors] = useState([]);
  const [errors, setErrors] = useState({});

  const [items, setItems] = useState([
    {
      description: "",
      quantity: 1,
      amount: "",
    },
  ]);

  useEffect(() => {
    setInvoiceData({
      dueDate:
        invoice?.due_date === null
          ? new Date().setDate(new Date().getDate() + 1)
          : new Date(invoice?.due_date * 1000),
      description: invoice.description,
    });
    setItems(invoice?.lines?.data);
  }, [invoice]);

  const handleToggle = () => {
    toggle();
    setItemErrors([]);
    setErrors({});
  };

  const handleAddItem = () => {
    const data = {
      description: "",
      quantity: 1,
      amount: "",
      isEdit: true,
    };
    setItems([...items, data]);
  };

  const validateItemsInputFields = (name, value) => {
    const obj = { [name]: value };
    const schema = { [name]: invoiceItemValidationSchema[name] };
    const { error } = Joi.validate(obj, schema);
    return error ? error?.details[0]?.message : null;
  };

  const handleChange = (key, value, index) => {
    let fieldErrors = { ...itemErrors };
    let data = [...items];
    let selectedItem = { ...data[index] };
    const errorMessage = validateItemsInputFields(key, value);
    if (fieldErrors) {
      fieldErrors[index] = { [key]: errorMessage };
    } else {
      delete fieldErrors[index][key];
    }
    selectedItem[key] = value;
    data[index] = selectedItem;
    setItems(data);
    setItemErrors(fieldErrors);
  };

  const mutation = useMutation(updateInvoice, {
    onSuccess: (response) => {
      toast.success(response?.message);
      queryClient.invalidateQueries(keys.invoices(invoice?.customer));
      handleToggle();
    },
  });

  const handleSubmit = () => {
    const payload = {
      id: invoice?.id,
      data: {
        dueDate: invoiceData.dueDate,
        description: invoiceData.description,
      },
    };
    mutation.mutate(payload);
  };

  const updateItemMutation = useMutation(updateInvoiceItem, {
    onSuccess: (response) => {
      toast.success(response?.message);
      queryClient.invalidateQueries(keys.invoices(invoice?.customer));
      let data = [...items];
      const item = data.find((obj) => obj.id === response?.item?.id);
      const index = data.indexOf(item);
      data[index] = response?.item;
      setItems(data);
    },
  });

  const handleUpdateInvoiceItem = (id, index) => {
    const payload = {
      id,
      data: {
        amount: items[index].amount,
        description: items[index].description,
      },
    };
    updateItemMutation.mutate(payload);
  };

  const createItemMutation = useMutation(createInvoiceItem, {
    onSuccess: (response) => {
      toast.success(response?.message);
      queryClient.invalidateQueries(keys.invoices(invoice?.customer));
    },
  });

  const handleCreateInvoiceItem = (index) => {
    const payload = {
      invoice: invoice?.id,
      customer: invoice?.customer,
      amount: items[index].amount,
      description: items[index].description,
    };
    createItemMutation.mutate(payload);
  };

  const handleChangeInvoiceData = (key, value) => {
    let data = { ...invoiceData };
    data[key] = value;
    setInvoiceData(data);
  };

  return (
    <Modal isOpen={isOpen} toggle={handleToggle} backdrop="static" size="lg">
      <ModalHeader toggle={handleToggle}>Send an Invoice</ModalHeader>
      <ModalBody>
        {mutation?.isError && (
          <Alert color="danger">{mutation.error?.message}</Alert>
        )}
        {updateItemMutation.isError && (
          <Alert color="danger">{updateItemMutation.error?.message}</Alert>
        )}
        {createItemMutation.isError && (
          <Alert color="danger">{createItemMutation.error?.message}</Alert>
        )}
        <Row form className="mt-3">
          <Col md={12}>
            <FormGroup>
              <Label>Due Date</Label>
              <DatePicker
                minDate={new Date()}
                selected={invoiceData.dueDate}
                className="form-control"
                onChange={(date) => handleChangeInvoiceData("dueDate", date)}
              />
              {errors?.dueDate && (
                <div className="text text-danger mt-1">{errors?.dueDate}</div>
              )}
            </FormGroup>
          </Col>

          <Col md={12}>
            <FormGroup>
              <Label>Description</Label>
              <Input
                type="textarea"
                rows={3}
                value={invoiceData.description}
                onChange={(e) =>
                  handleChangeInvoiceData("description", e.target.value)
                }
                maxLength={250}
              />
              <small className="float-right mt-1 text-muted">
                {invoiceData?.description?.length || 0}/250
              </small>
            </FormGroup>
          </Col>
          <Col className="mb-5">
            <IconButton
              className="float-right"
              color="primary"
              title="UPDATE"
              icon={<CloudArrowDownFill className="mr-2" />}
              disabled={mutation.isLoading}
              onClick={handleSubmit}
            />
          </Col>
        </Row>

        <h4>Invoice Items</h4>
        {items?.map((item, index) => (
          <Row form className="mt-2" key={index}>
            <Col md={4}>
              <FormGroup>
                <Label>Item Name</Label>
                <Input
                  value={items[index].description}
                  onChange={(e) =>
                    handleChange("description", e.target.value, index)
                  }
                  placeholder="Enter the item name"
                />
                {itemErrors[index]?.description && (
                  <div className="text text-danger mt-1">
                    {itemErrors[index]?.description}
                  </div>
                )}
              </FormGroup>
            </Col>
            <Col md={3}>
              <FormGroup>
                <Label>Quantity</Label>
                <Input
                  placeholder="Enter the item name"
                  value={items[index].quantity}
                  disabled
                />
              </FormGroup>
            </Col>
            <Col md={3}>
              <FormGroup>
                <Label>Price</Label>
                <Input
                  type="number"
                  placeholder="00.00"
                  value={items[index].amount / 100}
                  onChange={(e) =>
                    handleChange("amount", e.target.value * 100, index)
                  }
                />
                {itemErrors[index]?.amount && (
                  <div className="text text-danger mt-1">
                    {itemErrors[index]?.amount}
                  </div>
                )}
              </FormGroup>
            </Col>
            <Col md={2} style={{ marginTop: "35px" }}>
              {items[index].isEdit ? (
                <IconButton
                  icon={<PencilFill />}
                  color="primary"
                  className="mr-2"
                  onClick={() => handleCreateInvoiceItem(index)}
                  disabled={createItemMutation.isLoading}
                />
              ) : (
                <IconButton
                  icon={<PencilFill />}
                  color="primary"
                  className="mr-2"
                  onClick={() =>
                    handleUpdateInvoiceItem(items[index].id, index)
                  }
                  disabled={updateItemMutation.isLoading}
                />
              )}
            </Col>
          </Row>
        ))}

        <IconButton
          color="primary"
          title="Add Item"
          icon={<PlusCircle className="mr-2" />}
          onClick={handleAddItem}
        />
      </ModalBody>
      <ModalFooter>
        <IconButton
          onClick={handleToggle}
          title="Close"
          icon={<XCircle className="mr-2" />}
        />
      </ModalFooter>
    </Modal>
  );
};

export default InvoiceUpdateModal;
