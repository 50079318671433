import React from "react";
import { Link } from "react-router-dom";
import { useQuery } from "react-query";
import {
  Alert,
  Col,
  Container,
  ListGroup,
  ListGroupItem,
  Row,
  Spinner,
  Table,
} from "reactstrap";
import { getApplicationErrors } from "../../query/queryFunctions";
import { keys } from "../../query/keys";

const ErrorLogs = () => {
  const { isLoading, data: errors } = useQuery(
    keys.errors,
    getApplicationErrors
  );

  if (isLoading) {
    return (
      <div className="mt-5 text-center">
        <Spinner color="primary" />
      </div>
    );
  }

  return (
    <Container className="mt-5">
      <Row>
        <Col md="4">
          <ListGroup>
            <ListGroupItem>
              <Link to="/admin/settings">Settings</Link>
            </ListGroupItem>
          </ListGroup>
          <ListGroupItem>
            <Link to="/admin/error-logs">Error Logs</Link>
          </ListGroupItem>
        </Col>
        <Col md="12" className="mt-5">
          <Table responsive bordered hover>
            <thead>
              <tr className="text-center">
                <th>Request ID</th>
                <th>Connect Account</th>
                <th>Status Code</th>
                <th>Code</th>
                <th>Error Message</th>
                <th>Date</th>
              </tr>
            </thead>

            <tbody>
              {errors?.length > 0 ? (
                errors?.map(({ error }) => (
                  <tr key={error?.id}>
                    <td>{error?.requestId}</td>
                    <td>{error?.headers?.["stripe-account"]}</td>
                    <td>{error?.statusCode}</td>
                    <td>{error?.code}</td>
                    <td>{error?.message}</td>
                    <td>{error?.headers?.date}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={4}>
                    <Alert color="info">No Error Logs Found</Alert>
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </Col>
      </Row>
    </Container>
  );
};

export default ErrorLogs;
