import React, { useState } from "react";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { CloudArrowDownFill, XCircle } from "react-bootstrap-icons";
import {
  Alert,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import IconButton from "../Button";
import StripeCard from "../StripeCard";

const AddCustomerModal = ({
  isOpen,
  toggle,
  errors,
  customer,
  cardError,
  handleSubmit,
  setCardError,
  handleChange,
  creatingCustomer,
  userExistError,
}) => {
  const stripe = useStripe();
  const elements = useElements();

  const [isCheck, setIsCheck] = useState(false);

  const handleAddCustomer = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    if (isCheck) {
      const card = elements.getElement(CardElement);
      const result = await stripe.createToken(card);

      if (result.error) {
        setCardError(result.error.message);
      } else {
        setCardError("");
        const payload = {
          customer: {
            name: `${customer.firstName} ${customer.lastName}`,
            email: customer.email,
            description: customer.description,
          },
          card: result.token.id,
        };
        handleSubmit(payload);
      }
    } else {
      const payload = {
        customer: {
          name: `${customer.firstName} ${customer.lastName}`,
          email: customer.email,
          description: customer.description,
        },
      };
      handleSubmit(payload);
    }
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle} backdrop="static">
      <ModalHeader toggle={toggle}>Add New Customer</ModalHeader>
      <ModalBody>
        {cardError && <Alert color="danger">{cardError}</Alert>}
        {userExistError && <Alert color="danger">{userExistError}</Alert>}
        <FormGroup>
          <Label>First Name</Label>
          <Input
            value={customer.firstName}
            onChange={(e) => handleChange("firstName", e.target.value)}
            placeholder="Enter Customer's First Name"
          />
          {errors?.firstName && (
            <Alert color="danger" className="mt-1">
              {errors?.firstName}
            </Alert>
          )}
        </FormGroup>
        <FormGroup>
          <Label>Last Name</Label>
          <Input
            value={customer.lastName}
            onChange={(e) => handleChange("lastName", e.target.value)}
            placeholder="Enter Customer's Last Name"
          />
          {errors?.lastName && (
            <Alert color="danger" className="mt-1">
              {errors?.lastName}
            </Alert>
          )}
        </FormGroup>
        <FormGroup>
          <Label>Email</Label>
          <Input
            type="email"
            value={customer.email}
            onChange={(e) => handleChange("email", e.target.value)}
            placeholder="Enter Email Address"
          />
          {/* {emailError && <Alert color="danger">{emailError}</Alert>} */}
          {errors?.email && (
            <Alert color="danger" className="mt-1">
              {errors?.email}
            </Alert>
          )}
        </FormGroup>
        <FormGroup>
          <Label>Notes</Label>
          <Input
            type="textarea"
            rows={3}
            value={customer.description}
            onChange={(e) => handleChange("description", e.target.value)}
            placeholder="Add a note"
          />
          <small className="float-right mt-1 text-muted">
            {customer?.description?.length || 0}/250
          </small>
        </FormGroup>

        <FormGroup check inline className="mb-2">
          <Input
            type="checkbox"
            checked={isCheck}
            onChange={(e) => setIsCheck(e.target.checked)}
          />
          <Label check>Add User Card</Label>
        </FormGroup>
        {isCheck && (
          <FormGroup>
            <Label>Card Details</Label>
            <StripeCard />
          </FormGroup>
        )}
      </ModalBody>

      <ModalFooter>
        <IconButton
          onClick={toggle}
          title="Close"
          icon={<XCircle className="mr-2" />}
        />
        <IconButton
          color="primary"
          title="SAVE"
          icon={<CloudArrowDownFill className="mr-2" />}
          onClick={(e) => handleAddCustomer(e)}
          disabled={creatingCustomer}
        />
      </ModalFooter>
    </Modal>
  );
};

export default AddCustomerModal;
