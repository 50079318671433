import React, { useState } from "react";
import {
  Alert,
  FormGroup,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { CloudArrowDownFill, XCircle } from "react-bootstrap-icons";
import IconButton from "../Button";
import StripeCard from "../StripeCard";

const AddCard = ({
  isOpen,
  toggle,
  addingCard,
  mutate,
  isError,
  error,
  customerId,
}) => {
  const stripe = useStripe();
  const elements = useElements();

  const [cardError, setCardError] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    const card = elements.getElement(CardElement);
    const result = await stripe.createToken(card);

    if (result.error) {
      setCardError(result.error.message);
    } else {
      const payload = {
        cardToken: result.token.id,
        customerId,
      };
      mutate(payload);
    }
  };
  return (
    <Modal isOpen={isOpen} toggle={toggle} backdrop="static">
      <ModalHeader toggle={toggle}>Add Debit or Credit Card</ModalHeader>
      <ModalBody>
        {isError && <Alert color="danger">{error}</Alert>}
        {cardError && <Alert color="danger">{cardError}</Alert>}
        <FormGroup>
          <Label>Card Details</Label>
          <StripeCard />
        </FormGroup>
      </ModalBody>

      <ModalFooter>
        <IconButton
          onClick={toggle}
          title="Close"
          icon={<XCircle className="mr-2" />}
        />
        <IconButton
          color="primary"
          title="SAVE"
          icon={<CloudArrowDownFill className="mr-2" />}
          disabled={addingCard}
          onClick={(e) => handleSubmit(e)}
        />
      </ModalFooter>
    </Modal>
  );
};

export default AddCard;
