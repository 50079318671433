import React from "react";
import { XCircleFill, CloudArrowDownFill } from "react-bootstrap-icons";
import {
  Alert,
  FormGroup,
  Label,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import IconButton from "../Button";

const EditCustomerModal = ({
  isOpen,
  toggle,
  customer,
  errors,
  isLoading,
  handleChange,
  handleUpdate,
}) => {
  return (
    <Modal isOpen={isOpen} toggle={toggle} backdrop="static">
      <ModalHeader toggle={toggle}>Edit Customer</ModalHeader>
      <ModalBody>
        <FormGroup>
          <Label>Name</Label>
          <Input
            value={customer?.name}
            onChange={(e) => handleChange("name", e.target.value)}
            placeholder="Enter your name"
          />
          {errors?.name && (
            <Alert color="danger" className="mt-1">
              {errors?.name}
            </Alert>
          )}
        </FormGroup>
        <FormGroup>
          <Label>Email</Label>
          <Input
            type="email"
            value={customer?.email}
            onChange={(e) => handleChange("email", e.target.value)}
            placeholder="Please enter a valid email address"
          />
          {errors?.email && (
            <Alert color="danger" className="mt-1">
              {errors?.email}
            </Alert>
          )}
        </FormGroup>
        <FormGroup>
          <Label>Note</Label>
          <Input
            value={customer?.description}
            onChange={(e) => handleChange("description", e.target.value)}
            rows="3"
            type="textarea"
            placeholder="Write a note about that customer"
          />
          <small className="float-right mt-1 text-muted">
            {customer?.description?.length || 0}/250
          </small>
          {errors?.description && (
            <Alert color="danger" className="mt-1">
              {errors?.description}
            </Alert>
          )}
        </FormGroup>
      </ModalBody>
      <ModalFooter>
        <IconButton
          icon={<XCircleFill className="mr-2" />}
          title="Close"
          onClick={toggle}
          disabled={isLoading}
        />
        <IconButton
          color="primary"
          title="UPDATE"
          icon={<CloudArrowDownFill className="mr-2" />}
          onClick={handleUpdate}
          disabled={isLoading}
        />
      </ModalFooter>
    </Modal>
  );
};

export default EditCustomerModal;
