import AmericanCardIcon from "../assets/icons/American.png";
import DiscoverCardIcon from "../assets/icons/Discover.png";
import MasterCardIcon from "../assets/icons/master.png";
import VisaCardIcon from "../assets/icons/visa.png";
import DinersClubCardIcon from "../assets/icons/Diners.png";
import JCBCardIcon from "../assets/icons/JCB.png";
import UnionPayCardIcon from "../assets/icons/UnionPay.png";

export const CARD_TYPES = {
  "American Express": AmericanCardIcon,
  MasterCard: MasterCardIcon,
  Discover: DiscoverCardIcon,
  Visa: VisaCardIcon,
  "Diners Club": DinersClubCardIcon,
  UnionPay: UnionPayCardIcon,
  JCB: JCBCardIcon,
};

export const CARD_BRANDS = {
  amex: AmericanCardIcon,
  mastercard: MasterCardIcon,
  discover: DiscoverCardIcon,
  visa: VisaCardIcon,
  diners: DinersClubCardIcon,
  unionpay: UnionPayCardIcon,
  jcb: JCBCardIcon,
};
