import React from "react";
import { Alert, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { XCircle } from "react-bootstrap-icons";
import IconButton from "../Button";

const InvoiceCancel = ({
  isOpen,
  toggle,
  isError,
  isLoading,
  error,
  handleSubmit,
}) => {
  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>Cancel Invoice</ModalHeader>
      <ModalBody>
        {isError && <Alert color="danger">{error}</Alert>}
        <Alert color="danger">
          Are you sure, you want to cancel this invoice?
        </Alert>
      </ModalBody>

      <ModalFooter>
        <IconButton
          onClick={toggle}
          title="NO"
          icon={<XCircle className="mr-2" />}
          disabled={isLoading}
        />
        <IconButton
          color="danger"
          title="YES"
          icon={<XCircle className="mr-2" />}
          disabled={isLoading}
          onClick={handleSubmit}
        />
      </ModalFooter>
    </Modal>
  );
};

export default InvoiceCancel;
