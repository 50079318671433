import React from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

const user = JSON.parse(localStorage.getItem("user"));

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISH_KEY, {
  stripeAccount: user?.data?.apiKey,
});

const StripeElements = ({ children }) => {
  return <Elements stripe={stripePromise}>{children}</Elements>;
};

export default StripeElements;
