import React from "react";
import { XCircle } from "react-bootstrap-icons";
import { Modal, ModalBody, ModalFooter, ModalHeader, Table } from "reactstrap";
import IconButton from "../Button";

const TransactionDetailsModal = ({
  isOpen,
  toggle,
  charge,
  handleToggleRefundDetailsModal,
}) => {
  const calculateRemainingAmount = (amount, refunded) => {
    const result = amount - refunded;
    return Math.round((result + Number.EPSILON) * 100) / 100;
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle} backdrop="static" size="lg">
      <ModalHeader toggle={toggle}>Transaction Details</ModalHeader>
      <ModalBody>
        <Table responsive bordered hover>
          <thead>
            <tr className="text-center">
              <th>
                Card Number <small className="text-muted">(last4)</small>
              </th>
              <th>Amount</th>
              <th>Paid Date</th>
              <th>Refunded</th>
              <th>Remaining</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="text-center">
                ****{charge?.payment_method_details?.card?.last4}
              </td>
              <td className="text-center">${charge.amount / 100}</td>
              <td>{new Date(charge.created * 1000).toDateString()}</td>
              <td className="text-center">
                {(charge?.refunded || charge?.amount_refunded) && (
                  <span
                    className="badge badge-success"
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      handleToggleRefundDetailsModal(charge?.refunds)
                    }
                  >
                    Refunded ${charge.amount_refunded / 100}
                  </span>
                )}
              </td>
              <td className="text-center">
                $
                {calculateRemainingAmount(
                  charge?.amount / 100,
                  charge?.amount_refunded / 100
                )}
              </td>
            </tr>
          </tbody>
        </Table>
      </ModalBody>
      <ModalFooter>
        <IconButton
          title="Close"
          icon={<XCircle className="mr-2" />}
          onClick={toggle}
        />
      </ModalFooter>
    </Modal>
  );
};

export default TransactionDetailsModal;
