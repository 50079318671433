import React from "react";
import { ReplyFill, XCircle } from "react-bootstrap-icons";
import {
  Alert,
  FormGroup,
  Label,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import IconButton from "../Button";

const RefundModal = ({
  isOpen,
  toggle,
  loading,
  refundData,
  refundDataErrors,
  handleChange,
  handleSubmit,
}) => {
  // const handleChange = (key, value) => {
  //   let data = { ...refundData };
  //   data[key] = value;
  //   setRefundData(data);
  // };

  return (
    <Modal isOpen={isOpen} toggle={toggle} backdrop="static">
      <ModalHeader toggle={toggle}>
        Do you really want to <span className="text-danger">Refund</span>?
      </ModalHeader>
      <ModalBody>
        <FormGroup>
          <Label>Enter the amount to Refund</Label>
          <Input
            type="number"
            min={0}
            value={refundData.amount}
            onChange={(e) => handleChange("amount", e.target.value)}
            placeholder="Please specify the amount you want to refund"
          />
          {refundDataErrors?.amount && (
            <Alert color="danger" className="mt-1">
              {refundDataErrors?.amount}
            </Alert>
          )}
          <small className="text-muted">
            Note: Amount to refund can't exceed total
          </small>
        </FormGroup>
        <FormGroup>
          <Label>Reason</Label>
          <Input
            type="textarea"
            rows={3}
            value={refundData.reason}
            onChange={(e) => handleChange("reason", e.target.value)}
            placeholder="Please describe the reason here"
          />
          <small className="float-right mt-1 text-muted">
            {refundData?.reason?.length || 0}/250
          </small>
          {refundDataErrors?.reason && (
            <Alert color="danger" className="mt-1">
              {refundDataErrors?.reason}
            </Alert>
          )}
        </FormGroup>
      </ModalBody>

      <ModalFooter>
        <IconButton
          title="Close"
          icon={<XCircle className="mr-2" />}
          onClick={toggle}
          disabled={loading}
        />
        <IconButton
          title="Refund"
          color="danger"
          icon={<ReplyFill className="mr-2" />}
          onClick={handleSubmit}
          disabled={loading}
        />
      </ModalFooter>
    </Modal>
  );
};

export default RefundModal;
