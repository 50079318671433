import React from "react";
import { Route, Switch } from "react-router-dom";
import PrivateRoute from "../components/Hoc/privateRoute";
import Customers from "../pages/Customers";
import { routes } from "./routesList";

const routeTypes = { public: "public", private: "private" };
export const renderRoutes = (_routeArray, parentPath = "") =>
  _routeArray &&
  _routeArray.map((route) => {
    const path = parentPath + route.path;
    return [
      route.routeType === routeTypes.public ? (
        <Route
          exact={route.exact || true}
          path={path || "/"}
          component={route.component || Customers}
          roles={route.roles || ["admin", "user"]}
        />
      ) : (
        <PrivateRoute
          exact={route.exact || true}
          path={path || "/"}
          component={route.component || Customers}
          roles={route.roles || ["admin", "user"]}
        />
      ),
    ];
  });

const Routes = () => {
  return (
    <React.Fragment>
      <Switch>
        {renderRoutes(routes)}
        {/* <PrivateRoute component={PageNotFound} /> */}
      </Switch>
    </React.Fragment>
  );
};

export default Routes;
