import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useMutation } from "react-query";
import GoogleLogin from "react-google-login";
import { toast } from "react-toastify";
import { Alert, Container, Spinner } from "reactstrap";
import { adminLogin } from "../../query/queryFunctions";
import { useAuthContext } from "../../context/authContext";

const AdminLogin = () => {
  const history = useHistory();
  const { user, setUser } = useAuthContext();

  useEffect(() => {
    if (user.isAuthenticated && user?.data?.role === "admin") {
      history.push("/admin/settings");
    }
  }, [user.isAuthenticated, user?.data?.role, history]);

  const mutation = useMutation(adminLogin, {
    onSuccess: (response) => {
      setUser({
        token: response.token,
        isAuthenticated: true,
        data: {
          id: response.id,
          name: response.name,
          email: response.email,
          role: response.role,
          brand: response?.brand,
          logo: response?.brandLogo,
        },
      });
      toast.success("Login Successfully");
      history.push("/admin/settings");
    },
  });

  const { isLoading, isError, error } = mutation;

  const handleSubmit = async (response) => {
    mutation.mutate({ token: response.tokenId });
  };

  return (
    <Container className="mt-5 text-center">
      {isLoading ? (
        <Spinner color="primary" />
      ) : (
        <GoogleLogin
          clientId="534029500889-cuhutj5ikqfcb3s46j7sgpsv9kag5iu5.apps.googleusercontent.com"
          buttonText="Login With Google"
          onSuccess={(response) => handleSubmit(response)}
          onFailure={(error) => console.log(error)}
          cookiePolicy={"single_host_origin"}
        />
      )}
      {isError && (
        <Alert color="danger" className="mt-3">
          {error.message}
        </Alert>
      )}
      {/* <Row>
        <Col md={{ size: 6, offset: 3 }}>
          <Card>
            <CardHeader>Login</CardHeader>
            <CardBody>
              <FormGroup>
                <Label>Email</Label>
                <Input
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Enter Your Email Address"
                />
              </FormGroup>
              <FormGroup>
                <Label>Password</Label>
                <Input
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  type="password"
                  placeholder="Enter Your Password"
                />
              </FormGroup>
              <FormGroup>
                <button
                  disabled={isLoading}
                  onClick={handleSubmit}
                  className="btn btn-primary btn-block"
                >
                  LOGIN
                </button>
              </FormGroup>
            </CardBody>
          </Card>
        </Col>
      </Row> */}
    </Container>
  );
};

export default AdminLogin;
