import CustomerDetails from "../pages/CustomerDetails";
import Customers from "../containers/Customers";
import Login from "../pages/Login";
import AdminLogin from "../pages/Admin";
import Settings from "../pages/Admin/settings";
import ErrorLogs from "../pages/Admin/ErrorLogs";
import ClinicInvoices from "../pages/ClinicInvoices";
import PatientRegistration from "../pages/PatientRegistration";

const routeTypes = { public: "public", private: "private" };

export const routes = [
  {
    path: "/",
    exact: true,
    component: Login,
    routeType: routeTypes.public,
  },
  {
    path: "/customers",
    exact: true,
    component: Customers,
    routeType: routeTypes.private,
    roles: ["user"],
  },
  {
    path: "/invoices",
    exact: true,
    component: ClinicInvoices,
    routeType: routeTypes.private,
    roles: ["user"],
  },
  {
    path: "/customer/:id",
    exact: true,
    component: CustomerDetails,
    routeType: routeTypes.private,
    roles: ["user"],
  },
  {
    path: "/admin",
    exact: true,
    component: AdminLogin,
    routeType: routeTypes.public,
  },
  {
    path: "/admin/error-logs",
    exact: true,
    component: ErrorLogs,
    routeType: routeTypes.private,
    roles: ["admin"],
  },
  {
    path: "/admin/settings",
    exact: true,
    component: Settings,
    routeType: routeTypes.public,
    roles: ["admin"],
  },
  {
    path: "/patient-registration",
    exact: true,
    component: PatientRegistration,
    routeType: routeTypes.public,
    roles: ["user"],
  },
];
