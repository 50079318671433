import React from "react";
import { CheckCircleFill, XCircle } from "react-bootstrap-icons";
import {
  Alert,
  FormGroup,
  Label,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import IconButton from "../Button";

const PaymentModal = ({
  isOpen,
  toggle,
  isLoading,
  charge,
  errors,
  handleChange,
  handleSubmit,
}) => {
  return (
    <Modal isOpen={isOpen} toggle={toggle} backdrop="static">
      <ModalHeader toggle={toggle}>Make a Payment</ModalHeader>
      <ModalBody>
        <FormGroup>
          <Label>Enter the amount to Charge</Label>
          <Input
            type="number"
            min={0}
            value={charge.amount}
            onChange={(e) => handleChange("amount", e.target.value)}
            placeholder="Please specify the amount you want to charge"
          />
          {errors?.amount && (
            <Alert color="danger" className="mt-1">
              {errors?.amount}
            </Alert>
          )}
        </FormGroup>
        <FormGroup>
          <Label>Note</Label>
          <Input
            type="textarea"
            rows={3}
            value={charge.note}
            onChange={(e) => handleChange("note", e.target.value)}
            placeholder="Please write a description for this charge"
          />
          <small className="float-right mt-1 text-muted">
            {charge?.note?.length || 0}/250
          </small>
          {errors?.note && (
            <Alert color="danger" className="mt-1">
              {errors?.note}
            </Alert>
          )}
        </FormGroup>
      </ModalBody>

      <ModalFooter>
        <IconButton
          title="Close"
          icon={<XCircle className="mr-2" />}
          onClick={toggle}
          disabled={isLoading}
        />
        <IconButton
          title="Charge"
          color="success"
          icon={<CheckCircleFill className="mr-2" />}
          disabled={isLoading}
          onClick={handleSubmit}
        />
      </ModalFooter>
    </Modal>
  );
};

export default PaymentModal;
