import React, { useState } from "react";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import {
  Alert,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
} from "reactstrap";
import StripeElements from "../../components/StripeElements";
import { registerPatient } from "../../query/queryFunctions";
import PatientRegistrationForm from "./PatientRegistration";

const PatientRegistration = () => {
  const [customer, setCustomer] = useState({
    firstName: "",
    lastName: "",
    email: "",
    description: "",
  });

  const mutation = useMutation(registerPatient, {
    onSuccess: () => {
      toast.success("Registration Completed Successfully!");
      setCustomer({
        firstName: "",
        lastName: "",
        email: "",
        description: "",
      });
    },
  });

  const { isLoading, isError, isSuccess, error, mutate } = mutation;
  const handleSubmit = (payload) => {
    mutate(payload);
  };

  return (
    <Container className="mt-5">
      <Row>
        <Col md={{ size: 6, offset: 3 }}>
          <Card>
            <CardHeader>Customer Registration</CardHeader>
            <CardBody>
              {isError && <Alert color="danger">{error?.message}</Alert>}
              <StripeElements>
                <PatientRegistrationForm
                  isLoading={isLoading}
                  isSuccess={isSuccess}
                  customer={customer}
                  setCustomer={setCustomer}
                  handleSubmit={handleSubmit}
                />
              </StripeElements>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default PatientRegistration;
