import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useQuery, useMutation, useQueryClient } from "react-query";
import {
  Button,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Row,
  ListGroup,
  ListGroupItem,
  Spinner,
} from "reactstrap";
import {
  createSettings,
  getSettings,
  updateSettings,
} from "../../query/queryFunctions";
import { toast } from "react-toastify";
import { useAuthContext } from "../../context/authContext";

const Settings = () => {
  const queryClient = useQueryClient();
  const { user, setUser } = useAuthContext();

  const [brandName, setBrandName] = useState("");
  const [brandLogo, setBrandLogo] = useState("");
  const [apiKey, setApiKey] = useState("");
  const [domains, setDomains] = useState("");
  const [admins, setAdmins] = useState("");

  const [stripeFeePercentage, setStripeFeePercentage] = useState("");
  const [stripeAdditionalCharges, setStripeAdditionalCharges] = useState("");
  const [ourTotalFeePercentage, setOurTotalFeePercentage] = useState("");

  const { isLoading, data: settings } = useQuery("settings", getSettings);

  useEffect(() => {
    if (settings?.length > 0) {
      setBrandName(settings[0]?.brand);
      setApiKey(settings[0]?.apiKey);
      setBrandLogo(settings[0]?.brandLogo);
      setDomains(settings[0]?.allowedDomains.join(","));
      setAdmins(settings[0]?.allowedAdmins.join(","));
      setStripeFeePercentage(settings[0]?.stripeFeePercentage);
      setStripeAdditionalCharges(settings[0]?.stripeAdditionalCharges);
      setOurTotalFeePercentage(settings[0]?.ourTotalFeePercentage);
    }
  }, [settings]);

  const mutation = useMutation(createSettings, {
    onSuccess: (response) => {
      toast.success(response.message);
      queryClient.invalidateQueries("settings");
      let data = { ...user };
      data.data.brand = brandName;
      data.data.logo = brandLogo;
      setUser(data);
    },
  });

  const handleSubmit = () => {
    const payload = {
      apiKey,
      brandLogo,
      brand: brandName,
      stripeFeePercentage: parseFloat(stripeFeePercentage),
      ourTotalFeePercentage: parseFloat(ourTotalFeePercentage),
      stripeAdditionalCharges: parseFloat(stripeAdditionalCharges),
      domains: domains.split(","),
      allowedAdmins: admins.split(","),
    };

    mutation.mutate(payload);
  };

  const updateMutation = useMutation(updateSettings, {
    onSuccess: (response) => {
      toast.success(response.message);
      queryClient.invalidateQueries("settings");
      let data = { ...user };
      data.data.brand = brandName;
      data.data.logo = brandLogo;
      setUser(data);
    },
  });

  const handleUpdate = () => {
    const payload = {
      id: settings[0]._id,
      data: {
        apiKey,
        brandLogo,
        brand: brandName,
        stripeFeePercentage: parseFloat(stripeFeePercentage),
        ourTotalFeePercentage: parseFloat(ourTotalFeePercentage),
        stripeAdditionalCharges: parseFloat(stripeAdditionalCharges),
        domains: domains.split(","),
        allowedAdmins: admins.split(","),
      },
    };
    console.log(payload);
    updateMutation.mutate(payload);
  };

  return (
    <Container className="mt-5">
      <Row>
        <Col md="4">
          <ListGroup>
            <ListGroupItem>
              <Link to="/admin/settings">Settings</Link>
            </ListGroupItem>
            <ListGroupItem>
              <Link to="/admin/error-logs">Error Logs</Link>
            </ListGroupItem>
          </ListGroup>
        </Col>
        <Col md="8">
          {isLoading ? (
            <div className="text-center">
              <Spinner color="primary" />
            </div>
          ) : (
            <Row form>
              <Col md={6}>
                <FormGroup>
                  <Label>Brand Name</Label>
                  <Input
                    value={brandName}
                    onChange={(e) => setBrandName(e.target.value)}
                    placeholder="Please Specify your brand Name"
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label>Stripe Connect Account ID</Label>
                  <Input
                    value={apiKey}
                    onChange={(e) => setApiKey(e.target.value)}
                    placeholder="Please add your stripe connect account id"
                  />
                </FormGroup>
              </Col>

              <Col md={4}>
                <FormGroup>
                  <Label>Stripe Fee %</Label>
                  <Input
                    type="number"
                    value={stripeFeePercentage}
                    onChange={(e) => setStripeFeePercentage(e.target.value)}
                    placeholder="Please enter the stripe fee percentage"
                  />
                </FormGroup>
              </Col>

              <Col md={4}>
                <FormGroup>
                  <Label>Stripe Additional Charges</Label>
                  <Input
                    type="number"
                    value={stripeAdditionalCharges}
                    onChange={(e) => setStripeAdditionalCharges(e.target.value)}
                    placeholder="Please enter the additional charges"
                  />
                </FormGroup>
              </Col>

              <Col md={4}>
                <FormGroup>
                  <Label>Our Total Fee Percentage</Label>
                  <Input
                    type="number"
                    value={ourTotalFeePercentage}
                    onChange={(e) => setOurTotalFeePercentage(e.target.value)}
                    placeholder="Please enter the percentage of total fees"
                  />
                </FormGroup>
              </Col>

              <Col md={12}>
                <FormGroup>
                  <Label>Brand Logo</Label>
                  <Input
                    value={brandLogo}
                    onChange={(e) => setBrandLogo(e.target.value)}
                    placeholder="Please paste the URL of your brand logo"
                  />
                </FormGroup>
              </Col>

              <Col md={12}>
                <FormGroup>
                  <Label>Allowed Domains</Label>
                  <Input
                    value={domains}
                    onChange={(e) => setDomains(e.target.value)}
                    type="textarea"
                    rows={3}
                    placeholder="Please specify the domains that you want to allow to login to your application"
                  />
                  <small>
                    Please add " , " after a domain name to add one or multiple
                    domains. e.g joinsymbiosis.com,
                  </small>
                </FormGroup>
              </Col>

              <Col md={12}>
                <FormGroup>
                  <Label>Allowed Admins</Label>
                  <Input
                    value={admins}
                    onChange={(e) => setAdmins(e.target.value)}
                    type="textarea"
                    rows={3}
                    placeholder="Please specify the admin email address that you want to allow to login to your admin application"
                  />
                  <small>
                    Please add " , " after a domain name to add one or multiple
                    domains. e.g arun@joinsymbiosis.com,
                  </small>
                </FormGroup>
              </Col>

              <FormGroup className="mt-3">
                {settings?.length > 0 ? (
                  <Button
                    disabled={updateMutation.isLoading}
                    onClick={handleUpdate}
                    color="primary"
                  >
                    UPDATE
                  </Button>
                ) : (
                  <Button
                    disabled={mutation.isLoading}
                    onClick={handleSubmit}
                    color="primary"
                  >
                    SAVE
                  </Button>
                )}
              </FormGroup>
            </Row>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default Settings;
