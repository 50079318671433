import React, { useEffect } from "react";
import GoogleLogin from "react-google-login";
import { useQuery, useMutation } from "react-query";
import { Alert, Container } from "reactstrap";
import { useAuthContext } from "../../context/authContext";
import { userLogin, getSettings } from "../../query/queryFunctions";

const Login = () => {
  const { user, setUser } = useAuthContext();
  const { data: settings } = useQuery("settings", getSettings);
  const mutation = useMutation(userLogin, {
    onSuccess: (response) => {
      setUser({
        token: response.token,
        isAuthenticated: true,
        data: {
          name: response.name,
          email: response.email,
          avatar: response.picture,
          brand: response?.brand,
          apiKey: response?.apiKey,
          role: response.role,
          logo: response?.brandLogo,
        },
      });
    },
  });
  useEffect(() => {
    if (user.isAuthenticated && user?.data?.role === "user") {
      window.location = "/customers";
    }
  }, [user.isAuthenticated, user?.data?.role]);

  const handleSubmit = async (response) => {
    mutation.mutate({ token: response.tokenId });
  };

  if (!settings || settings.length <= 0) {
    return (
      <Container className="mt-5 text-center">
        <Alert color="warning">You are not allowed to access this page</Alert>
      </Container>
    );
  }
  return (
    <Container className="mt-5 text-center">
      <GoogleLogin
        clientId="534029500889-cuhutj5ikqfcb3s46j7sgpsv9kag5iu5.apps.googleusercontent.com"
        buttonText="Login With Google"
        onSuccess={(response) => handleSubmit(response)}
        onFailure={(error) => console.log(error)}
        cookiePolicy={"single_host_origin"}
      />
      {mutation?.isError && (
        <div className="mt-3">
          <Alert color="danger">{mutation?.error?.message}</Alert>
        </div>
      )}
    </Container>
  );
};

export default Login;
