import React from "react";
import { ExclamationTriangleFill, XCircle } from "react-bootstrap-icons";
import { Alert, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import IconButton from "../Button";

const RemoveCardModal = ({
  isOpen,
  toggle,
  removingCard,
  handleRemoveCard,
}) => {
  return (
    <Modal isOpen={isOpen} toggle={toggle} backdrop="static">
      <ModalHeader toggle={toggle}>Remove Card?</ModalHeader>
      <ModalBody>
        <Alert color="danger">
          Do you want to remove this card permanentaly?
        </Alert>
      </ModalBody>

      <ModalFooter>
        <IconButton
          title="Close"
          icon={<XCircle className="mr-2" />}
          onClick={toggle}
        />
        <IconButton
          title="Remove"
          color="danger"
          icon={<ExclamationTriangleFill className="mr-2" />}
          disabled={removingCard}
          onClick={handleRemoveCard}
        />
      </ModalFooter>
    </Modal>
  );
};

export default RemoveCardModal;
