import React from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import NavBar from "./components/Navbar";
import { AuthProvider } from "./context/authContext";
import Routes from "./routes";

function App() {
  return (
    <AuthProvider>
      <div className="App">
        <NavBar />
        <Routes />

        <ToastContainer />
      </div>
    </AuthProvider>
  );
}

export default App;
