import React, { useState } from "react";
import { PersonCircle } from "react-bootstrap-icons";
import { useHistory } from "react-router-dom";
import {
  Container,
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { useAuthContext } from "../../context/authContext";
import LogoImage from "../../assets/icons/logo.png";

const NavBar = () => {
  const history = useHistory();
  const { user, setUser } = useAuthContext();
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  const handleLogOut = () => {
    if (user?.data?.role === "admin") {
      history.push("/admin");
    } else {
      history.push("/");
    }
    setUser({ token: null, isAuthenticated: false, data: {} });
  };

  return (
    <div>
      <Navbar color={user.isAuthenticated ? "dark" : "light"} dark expand="md">
        <Container>
          {!user.isAuthenticated ? (
            <NavbarBrand className="d-flex align-items-center">
              <img
                src={LogoImage}
                alt="brand logo"
                // width={50}
                height={50}
                // style={{ borderRadius: "50%" }}
              />
            </NavbarBrand>
          ) : (
            <NavbarBrand className="d-flex align-items-center">
              {user?.data?.logo && (
                <img
                  src={user?.data?.logo}
                  alt="brand logo"
                  width={50}
                  height={50}
                  style={{ borderRadius: "50%" }}
                />
              )}
              <span className="ml-2">{user?.data?.brand}</span>
            </NavbarBrand>
          )}
          {user?.isAuthenticated && user?.data?.role === "user" && (
            <Nav navbar>
              <NavItem>
                <NavLink href="/customers">Customers</NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="/invoices">Invoices</NavLink>
              </NavItem>
            </Nav>
          )}
          {user?.isAuthenticated && (
            <Nav navbar>
              <NavItem>
                <NavLink href="https://dashboard.stripe.com/">
                  Stripe Dashboard
                </NavLink>
              </NavItem>
            </Nav>
          )}
          <NavbarToggler onClick={toggle} />
          {user?.isAuthenticated && (
            <Collapse isOpen={isOpen} navbar>
              <Nav className="ml-auto" navbar>
                <NavItem>
                  <UncontrolledDropdown nav inNavbar>
                    <DropdownToggle nav>
                      {user?.data?.avatar ? (
                        <img
                          src={user?.data?.avatar}
                          alt="avatar"
                          width={30}
                          style={{ borderRadius: "50%" }}
                        />
                      ) : (
                        <PersonCircle />
                      )}
                    </DropdownToggle>
                    <DropdownMenu right>
                      <DropdownItem>{user?.data?.name}</DropdownItem>
                      <DropdownItem divider />
                      <DropdownItem onClick={handleLogOut}>LogOut</DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </NavItem>
              </Nav>
            </Collapse>
          )}
        </Container>
      </Navbar>
    </div>
  );
};

export default NavBar;
