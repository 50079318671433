import React from "react";
import { XCircle } from "react-bootstrap-icons";
import { Modal, ModalBody, ModalFooter, ModalHeader, Table } from "reactstrap";
import IconButton from "../Button";

const RefundDetailsModal = ({ isOpen, toggle, refunds }) => {
  return (
    <Modal isOpen={isOpen} toggle={toggle} backdrop="static">
      <ModalHeader toggle={toggle}>Refund Details</ModalHeader>
      <ModalBody>
        <Table responsive bordered hover>
          <thead>
            <tr>
              <th>Date</th>
              <th>Amount</th>
              <th>Reason</th>
            </tr>
          </thead>
          <tbody>
            {refunds?.data &&
              refunds?.data.map((item, index) => (
                <tr key={index}>
                  <td>{new Date(item.created * 1000).toDateString()}</td>
                  <td className="text-center">${item.amount / 100}</td>
                  <td>{item?.metadata?.note}</td>
                </tr>
              ))}
          </tbody>
        </Table>
      </ModalBody>
      <ModalFooter>
        <IconButton
          title="Close"
          icon={<XCircle className="mr-2" />}
          onClick={toggle}
        />
      </ModalFooter>
    </Modal>
  );
};

export default RefundDetailsModal;
