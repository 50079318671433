import React from "react";
import { TrashFill, XCircle } from "react-bootstrap-icons";
import { Alert, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import IconButton from "../Button";

const CustomerDeleteModal = ({
  isOpen,
  toggle,
  handleSubmit,
  deletingCustomer,
}) => {
  return (
    <Modal isOpen={isOpen} toggle={toggle} backdrop="static">
      <ModalHeader toggle={toggle}>Delete Customer?</ModalHeader>
      <ModalBody>
        <Alert color="danger">
          Are you sure, you want to delete this customer?
        </Alert>
      </ModalBody>
      <ModalFooter>
        <IconButton
          title="Close"
          icon={<XCircle className="mr-2" />}
          onClick={toggle}
          disabled={deletingCustomer}
        />

        <IconButton
          title="Delete"
          color="danger"
          icon={<TrashFill className="mr-2" />}
          disabled={deletingCustomer}
          onClick={handleSubmit}
        />
      </ModalFooter>
    </Modal>
  );
};

export default CustomerDeleteModal;
