import React from "react";
import { Alert, Col, Row, Table } from "reactstrap";
import { Cash, XCircle } from "react-bootstrap-icons";
import IconButton from "../../../components/Button";
import { CARD_TYPES } from "../../../utils/constants";

const SavedCards = ({
  cards,
  isLoading,
  setCardId,
  defaultCard,
  togglePaymentModal,
  toggleRemoveCardModal,
  handleUpdateCustomer,
}) => {
  const handleRemoveCardModalToggle = (id) => {
    toggleRemoveCardModal();
    setCardId(id);
  };

  const handlePaymentModalToggle = (id) => {
    togglePaymentModal();
    setCardId(id);
  };

  return (
    <Row>
      <Col sm="12">
        <div className="d-flex flex-column">
          <Table responsive bordered hover>
            <thead>
              <tr>
                <th></th>
                <th className="text-center">
                  Card Number <small className="text-muted">(last4)</small>
                </th>
                <th className="text-center">Expiry Date</th>
                <th className="text-center">Action(s)</th>
              </tr>
            </thead>

            <tbody>
              {cards?.data?.length > 0 ? (
                cards?.data?.map((card, index) => (
                  <tr key={index}>
                    <td className="text-center">
                      <img
                        title={card.brand}
                        src={CARD_TYPES[card.brand]}
                        alt={card.brand}
                        width={30}
                      />
                    </td>
                    <td>
                      *******{card.last4}{" "}
                      {card.id === defaultCard && (
                        <span className="ml-5 badge badge-success">
                          Default
                        </span>
                      )}
                    </td>
                    <td className="text-center">
                      {" "}
                      {card.exp_month}/{card.exp_year.toString().slice(2)}
                    </td>
                    <td className="text-right">
                      {card.id !== defaultCard && (
                        <IconButton
                          title="Default"
                          className="btn-sm mr-2"
                          color="info"
                          onClick={() => handleUpdateCustomer(card.id)}
                          disabled={isLoading}
                        />
                      )}
                      <IconButton
                        title="Charge"
                        className="btn-sm"
                        color="success"
                        icon={<Cash className="mr-2" />}
                        onClick={() => handlePaymentModalToggle(card.id)}
                      />
                      <IconButton
                        title="Remove"
                        className="btn-sm ml-2"
                        color="danger"
                        icon={<XCircle className="mr-2" />}
                        onClick={() => handleRemoveCardModalToggle(card.id)}
                      />
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={4}>
                    <Alert color="info">No Card's Found</Alert>
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>
      </Col>
    </Row>
  );
};

export default SavedCards;
